<template>
	<div class="create">
		<h1>create page</h1>
	</div>
</template>

<script>
export default {
	name: 'CreatePage',
};
</script>

<style>

</style>
